









import { Component } from 'vue-property-decorator';

import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';

@Component
export default class UiOnBoardingStepWidget extends VueRegisterStoreWidget {
  private get currentStep(): number {
    return this.$store.state.WidgetDispatcherStore[this.parentStoreName].currentStep;
  }

  private get onboardingPositions(): string[] {
    return this.$store.state.WidgetDispatcherStore[this.parentStoreName].onboardingPositions;
  }

  private get parentStoreName(): string {
    return `DestinationUiOnboarding${this.widget.onboarding?.id}${this.widget.page?.id}`;
  }

  private get position(): number {
    if (this.onboardingPositions) {
      return this.onboardingPositions.findIndex((uid) => uid === this.widget.uid) + 1;
    }
    return 1;
  }

  created(): void {
    this.setDataConfig();
  }
}
